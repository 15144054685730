// Simple file for operations related to auth0 that don't fit in the react-auth0-spa.js file
import axios from 'axios'

import { store } from './state'

import { getApplications } from './applications'

import auth0config from '../auth_config.json'

export function updateAuth0AccessToken() {
    if (store.auth0.accessToken && !isTokenExpired()) return Promise.resolve();

    return getApplications().then(response => {
        if (!response.length) throw new Error("No Auth0 Applications set up")
        return response[0]
    }).then(application =>{
        return updateAuth0AccessTokenFromApp(application)
    }).catch((err) => {
        store.auth0.accessToken = '';
        store.auth0.expires = 0;
        console.log(err);
    })
}
// let auth0AccessTokenResponse;

// we're currently just using this method to update an accesstoken maintained in the store,
// instead of returning it in this function. This is a bit of a blend of coding styles in this application
// and it works, so I'm tempted to leave it.
export function updateAuth0AccessTokenFromApp(application) {
    //console.log(store.auth0.accessToken)
    const config = getSampleTokenConfig(application)
    return axios(config).then((response) => {
        store.auth0.accessToken = response.data.access_token;
        store.auth0.expires = getCurrentTimeSeconds() + response.data.expires_in;
    }).catch((err) => {
        store.auth0.accessToken = '';
        store.auth0.expires = 0;
        console.log(err);
    })
}
//let auth0AccessTokenResponseForApp;

function getSampleTokenConfig(sampleApp) {
    return {
        method: 'post',
        url: getTokenUrl(),
        headers: {
            'content-type': 'application/json'
        },
        data: {
            'client_id': sampleApp.client_id,
            'client_secret': sampleApp.client_secret,
            'audience': getPublicAPIAudience(),
            'grant_type': 'client_credentials'
        }
    }
}

export function getCurrentTimeSeconds() {
    return new Date().getTime() / 1000;
}

function isTokenExpired() {
    return store.auth0.expires <= getCurrentTimeSeconds();
}

export function getTokenUrl() {
    if (auth0config.customTokenAuthUrl) {
        return auth0config.customTokenAuthUrl;
    } else {
        return getTokenUrlFromAudience(auth0config.audience);
    }
}

export function getTokenUrlFromAudience(audience) {
    const match = audience.match(/^https:\/\/(.*)-developerportal-api\/$/);
    if (!match) {
        throw new Error(`Invalid audience - does not match "-developerportal-api"`);
    }
    const split = match[1].split("-")
    if (split.length === 2) {
        return `https://api.${split[0]}${split[1]}.idscloud.io/token-auth/token`;
    } else {
        return `https://api.${split[0]}${split[1]}-${split[2]}.idscloud.io/token-auth/token`;
    }
}

export function getPublicAPIAudience() {
    return auth0config.publicAPIAudience
}
