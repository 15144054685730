import React, { useEffect, useState } from 'react'
import { store } from 'services/state.js'

// services
import { updateAuth0AccessTokenFromApp } from 'services/auth0'

const tokenSampleStyle = {
    background: 'black',
    border: '1px solid gray',
    padding: '7px 8px',
    color: 'lightgray',
    borderRadius: '5px',
    resize: 'none',
    width: '100%',
    height: '200px'
};

const GettingStartedResponse = (props) => {

    //const [accessToken, setAccessToken] = useState("")
    const [responseTokenSample, setResponseTokenSample] = useState("")

    //const [loading, setLoading] = useState(true)

    const getResponseSampleText = (token) => { 
        return  `{
            "access_token": "${token}",
            "token_type": "Bearer"
        }`
    }

    // Initial load
    useEffect(() => {
        if (!props.application || !props.application.name ) return
        let isMounted = true;

        //console.log(props.application)
        updateAuth0AccessTokenFromApp(props.application).then(() => {
            if (isMounted) {
                //setAccessToken(store.auth0.accessToken);
                setResponseTokenSample(getResponseSampleText(store.auth0.accessToken))
            }
        })//.finally(() => {if (isMounted) setLoading(false)})
        return () => { 
            isMounted = false; 
        }
    }, [props.application])

    
    return (
        <div>
            <textarea readOnly id="noter-text-area" name="textarea" value={responseTokenSample} style={tokenSampleStyle} />
        </div>
    );
  }
  export default GettingStartedResponse
