import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
import { Header, Segment, Container, Image, Card} from 'semantic-ui-react'

export const ApisHomePage = observer(() => (
  <>
    <Segment vertical textAlign='left' style={{ padding: '40px 0px', margin: '0px !important' }}>
      <Container fluid text>
        <Header as='h1' >{fragments.APIs.header}</Header>
        <p>{fragments.APIs.description}</p>
      </Container>
    </Segment>
    <Segment vertical >
      <Card.Group centered>
        <Card href='/getting-started' color='blue' data-testid="gettingStartedLink">
          <Image src='/custom-content/Sandbox-small.png' as='div' centered size='small'/>
          <Card.Content>
            <Card.Header>Getting Started</Card.Header>
            <Card.Description>Quickstart guide for using the Developer Portal and Solifi Open Finance Platform APIs</Card.Description>
          </Card.Content>
        </Card>
        <Card href='/generate-an-access-token' color='blue'>
          <Image src='/custom-content/Build-Test-Deploy-small.png' as='div' centered size='small'/>
          <Card.Content>
            <Card.Header>Generate an Access Token</Card.Header>
            <Card.Description>Authenticate programmatically using your application</Card.Description>
          </Card.Content>
        </Card>
        <Card href="/apis" color='blue'>
          <Image src='/custom-content/Connected-Experiences-small.png' as='div' centered size='small'/>
          <Card.Content>
            <Card.Header>API Catalog</Card.Header>
            <Card.Description>Catalog of exposed APIs for integration</Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
    </Segment>
  </>
))

export default ApisHomePage
