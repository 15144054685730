import React from 'react'

import { Button, Loader, Table, Popup, Icon } from 'semantic-ui-react'

import { apiGatewayClient } from 'services/api'
import { getApi } from 'services/api-catalog'
import { store } from 'services/state'

import * as YAML from 'yamljs'

import hash from 'object-hash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

function getUsagePlanVisibility(usagePlan) {
  let hasHidden = false
  let hasVisible = false

  for (const api of usagePlan.apis) {
    if (api.visibility) {
      if (hasHidden) return null
      hasVisible = true
    } else {
      if (hasVisible) return null
      hasHidden = true
    }
  }

  return hasVisible
}

export const ApiManagement = observer(class ApiManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      errors: [],
      apisUpdating: []
    }

    this.fileInput = React.createRef()

    this.tableSort = (first, second) => {
      if (first.name !== second.name) {
        return first.name.localeCompare(second.name)
      } else {
        return first.stage.localeCompare(second.stage)
      }
    }

    this.genericTableSort = (firstIndex, secondIndex) => {
      const list = store.visibility.generic

      if (list[firstIndex].name !== list[secondIndex].name) {
        list[firstIndex].name.localeCompare(list[secondIndex].name)
      } else {
        // compare by their index, which happens to be their id
        return firstIndex.localeCompare(secondIndex)
      }
    }

    this.usagePlanSort = (first, second) => {
      if (first.name !== second.name) {
        return first.name.localeCompare(second.name)
      } else {
        return first.id.localeCompare(second.id)
      }
    }
  }

  componentDidMount() {
    this.getApiVisibility()
  }

  uploadAPISpec(event) {
    event.preventDefault()

    const files = this.fileInput.current.files
    let swagger, swaggerObject, anyFailures

    if (files.length > 0) {
      this.setState(prev => ({ ...prev, errors: [] }))
        ;[].forEach.call(files, file => {
          const reader = new window.FileReader()

          reader.onload = (e) => {
            if (file.name.includes('yaml')) {
              swaggerObject = YAML.parse(e.target.result)
              swagger = JSON.stringify(swaggerObject)
            } else {
              swaggerObject = JSON.parse(e.target.result)
              swagger = JSON.stringify(swaggerObject)
            }

            if (!(swaggerObject.info && swaggerObject.info.title)) {
              anyFailures = true
              this.setState(prev => ({ ...prev, errors: [...prev.errors, file.name] }))
              return
            }

            if (anyFailures) {
              return
            }

            apiGatewayClient()
              .then((app) => app.post('/admin/catalog/visibility', {}, { swagger }, {}))
              .then((res) => {
                if (res.status === 200) {
                  this.setState(prev => ({ ...prev, modalOpen: Boolean(anyFailures), errors: anyFailures ? prev.errors : [] }))
                }
                setTimeout(() => this.getApiVisibility(), 2000)
              })
          }
          reader.readAsText(file)
        })
    }
  }

  deleteAPISpec(apiId) {
    getApi(apiId, false, undefined, true).then(api => {
      const _api = toJS(api)
      const myHash = hash(_api.swagger)

      apiGatewayClient()
        .then(app => app.delete(`/admin/catalog/visibility/generic/${myHash}`, {}, {}, {}))
        .then((res) => {
          setTimeout(() => this.getApiVisibility(), 2000)
        })
    })
  }

  getApiVisibility() {
    apiGatewayClient()
      .then(app => app.get('/admin/catalog/visibility', {}, {}, {}))
      .then(res => {
        if (res.status === 200) {
          // console.log(`visibility: ${JSON.stringify(res.data, null, 2)}`)

          const apiGateway = res.data.apiGateway
          const generic = res.data.generic && Object.keys(res.data.generic)

          // console.log(`generic: ${JSON.stringify(generic, null, 2)}`)
          // console.log(`api gateway: ${JSON.stringify(apiGateway, null, 2)}`)

          apiGateway.forEach(api => {
            if (generic) {
              generic.forEach(genApi => {
                if (res.data.generic[`${genApi}`]) {
                  if (
                    res.data.generic[`${genApi}`].apiId === api.id &&
                    res.data.generic[`${genApi}`].stage === api.stage
                  ) {
                    api.visibility = true
                    delete res.data.generic[`${genApi}`]
                  }
                }
              })
            }
          })

          store.visibility = res.data
        }
      })
  }

  updateLocalApiGatewayApis(apisList, updatedApi, parity) {
    const updatedApis = apisList.map(stateApi => {
      if (stateApi.id === updatedApi.id && stateApi.stage === updatedApi.stage) {
        if (parity !== undefined && (parity === true || parity === false)) {
          stateApi.visibility = parity
        } else {
          stateApi.visibility = !stateApi.visibility
        }
      }
      return stateApi
    })

    store.visibility = { generic: store.visibility.generic, apiGateway: updatedApis }
  }

  showApiGatewayApi(api) {
    apiGatewayClient()
      .then(app => app.post('/admin/catalog/visibility', {}, { apiKey: `${api.id}_${api.stage}`, subscribable: `${api.subscribable}` }, {}))
      .then((res) => {
        if (res.status === 200) {
          this.updateLocalApiGatewayApis(store.visibility.apiGateway, api)
        }
      })
  }

  hideApiGatewayApi(api) {
    if (!api.subscribable && !api.id && !api.stage) {
      this.deleteAPISpec(api.genericId)
    } else {
      apiGatewayClient()
        .then(app => app.delete(`/admin/catalog/visibility/${api.id}_${api.stage}`, {}, {}, {}))
        .then((res) => {
          if (res.status === 200) {
            this.updateLocalApiGatewayApis(store.visibility.apiGateway, api)
          }
        })
    }
  }

  showAllApiGatewayApis(usagePlan) {
    Promise.all(usagePlan.apis.map((api) =>
      apiGatewayClient()
        .then(app => app.post('/admin/catalog/visibility', {}, {
          apiKey: `${api.id}_${api.stage}`,
          subscribable: `${api.subscribable}`
        }, {}))
        .then(res => { res.api = api; return res })
    )).then((promises) => {
      promises.forEach((result) => {
        if (result.status === 200) {
          this.updateLocalApiGatewayApis(store.visibility.apiGateway, result.api, true)
        }
      })
    })
  }

  hideAllApiGatewayApis(usagePlan) {
    Promise.all(usagePlan.apis.map((api) =>
      apiGatewayClient()
        .then(app => app.delete(`/admin/catalog/visibility/${api.id}_${api.stage}`, {}, {}, {}))
        .then(res => { res.api = api; return res })
    )).then((promises) => {
      promises.forEach((result) => {
        if (result.status === 200) {
          this.updateLocalApiGatewayApis(store.visibility.apiGateway, result.api, false)
        }
      })
    })
  }

  isUpdatingApiGatewayApi(api) {
    return this.state.apisUpdating.includes(`${api.id}_${api.stage}`)
  }

  updateApiGatewayApi(api) {
    // Simpler than implementing a multiset, and probably also faster.
    this.setState(({ apisUpdating }) => ({
      apisUpdating: [...apisUpdating, `${api.id}_${api.stage}`]
    }))
    apiGatewayClient()
      .then(app => app.post('/admin/catalog/visibility', {}, { apiKey: `${api.id}_${api.stage}`, subscribable: `${api.subscribable}` }, {}))
      .then(() => this.setState(({ apisUpdating }) => {
        const index = apisUpdating.indexOf(`${api.id}_${api.stage}`)
        const newApisUpdating = apisUpdating.slice()
        newApisUpdating.splice(index, 1)
        return { apisUpdating: newApisUpdating }
      }))
  }

  isSdkGenerationConfigurable(api) {
    return api.visibility
  }

  toggleSdkGeneration(apisList, updatedApi) {
    apiGatewayClient()
      .then(app => {
        if (updatedApi.sdkGeneration) {
          return app.delete(`/admin/catalog/${updatedApi.id}_${updatedApi.stage}/sdkGeneration`, {}, {}, {})
        } else {
          return app.put(`/admin/catalog/${updatedApi.id}_${updatedApi.stage}/sdkGeneration`, {}, {}, {})
        }
      })
      .then(res => {
        if (res.status === 200) {
          const updatedApis = apisList.map(stateApi => {
            if (stateApi.id === updatedApi.id && stateApi.stage === updatedApi.stage) {
              stateApi.sdkGeneration = !stateApi.sdkGeneration
            }
            return stateApi
          })

          store.visibility.apiGateway = updatedApis
        }
      })
  }

  renderHeaderVisibilityButton(usagePlan) {
    const usagePlanVisibility = getUsagePlanVisibility(usagePlan)

    // Some APIs are visible, some are hidden. Show the current state (Partial, with a warning) and enable all on click
    if (usagePlanVisibility == null) {
      return (
        <Popup
          content='Users subscribed to any of the APIs in this usage plan will have a valid API key for all APIs in this usage plan, even those that are not visible!'
          trigger={
            <Button
              basic
              color='yellow'
              style={{ backgroundColor: 'white', width: '100%', paddingLeft: '1em', paddingRight: '1em', minWidth: '88px' }}
              onClick={() => this.showAllApiGatewayApis(usagePlan)}
            >
              Partial <Icon name='warning sign' style={{ paddingLeft: '5px' }} />
            </Button>
          }
        />
      )
    }

    // Either all APIs are visible or none are visible. Toggle this state on click.
    return (
      <Button
        style={
          usagePlanVisibility ?
          {
            width: '100%',
            'background-color': 'var(--teal)',
            color: 'black'
          } :
          {
            width: '100%',
            'background-color': 'var(--danger)',
            color: 'white'
          }
        }
        onClick={() => {
          if (usagePlanVisibility) this.hideAllApiGatewayApis(usagePlan)
          else this.showAllApiGatewayApis(usagePlan)
        }}
      >
        {usagePlanVisibility ? 'True' : 'False'}
      </Button>
    )
  }

  sortByUsagePlan() {
    if (!store.visibility.apiGateway) { return this.renderNoApis() }

    const usagePlans =
      store.visibility.apiGateway
        .filter((api) => api.usagePlanId)
        .reduce((accumulator, api) => {
          if (!accumulator.find((usagePlan) => api.usagePlanId === usagePlan.id)) {
            accumulator.push({ id: api.usagePlanId, name: api.usagePlanName })
          }
          return accumulator
        }, [])
        .sort(this.usagePlanSort)
        .map((usagePlan) => {
          return { ...usagePlan, apis: store.visibility.apiGateway.filter((api) => api.usagePlanId === usagePlan.id).sort(this.tableSort) }
        })
    const unsubscribable =
      store.visibility.apiGateway
        .filter((api) => !api.usagePlanId)
        .sort(this.tableSort)

    return (
      <>
        {usagePlans.map(usagePlan => {
          return (
            <>
              {this.renderHeader(usagePlan)}
              {this.renderApiList(usagePlan.apis)}
            </>
          )
        })}
        <Table.Row style={{ backgroundColor: 'var(--dark-gray)', color: 'white' }}>
          <Table.Cell colSpan='6'>
            <b>Not Subscribable</b> <i>No Usage Plan</i>
          </Table.Cell>
        </Table.Row>
        {this.renderApiList(unsubscribable)}
      </>
    )
  }

  renderNoApis() {
    return (
      <Table.Row>
        <Table.Cell colSpan='4'>
          No APIs found
        </Table.Cell>
      </Table.Row>
    )
  }

  renderHeader(usagePlan) {
    return (
      <Table.Row style={{ backgroundColor: 'var(--dark-gray)', color: 'white' }}>
        <Table.Cell colSpan='3'>
          <b>{usagePlan && usagePlan.name}</b> <i>Usage Plan</i>
        </Table.Cell>
        <Table.Cell>
          {this.renderHeaderVisibilityButton(usagePlan)}
        </Table.Cell>
        <Table.Cell colSpan='2'>
          {/* Intentionally empty */}
        </Table.Cell>
      </Table.Row>
    )
  }

  renderApiList(apis) {
    return <>
      {apis.filter(api => api.id !== window.config.restApiId).map(api => (
        <React.Fragment key={api.stage ? `${api.id}_${api.stage}` : api.id}>
          <Table.Row>
            <Table.Cell collapsing>{api.name}</Table.Cell>
            <Table.Cell>{api.id}</Table.Cell>
            <Table.Cell>{api.stage}</Table.Cell>
            <Table.Cell>{api.subscribable ? 'Subscribable' : 'Not Subscribable'}</Table.Cell>
            <Table.Cell>
              <Button
                style={
                  api.visibility ?
                  {
                    width: '100%',
                    'background-color': 'var(--teal)',
                    color: 'black'
                  } :
                  {
                    width: '100%',
                    'background-color': 'var(--danger)',
                    color: 'white'
                  }
                }
                onClick={() => api.visibility ? this.hideApiGatewayApi(api) : this.showApiGatewayApi(api)}
              >
                {api.visibility ? 'True' : 'False'}
              </Button>
            </Table.Cell>
            <Table.Cell>
              <Button
                disabled={!api.visibility}
                style={{ width: '100%', 'background-color': 'var(--indigo)', color: 'white' }}
                onClick={() => this.updateApiGatewayApi(api)}
              >
                {this.isUpdatingApiGatewayApi(api) ? <Loader active inline size='mini' /> : 'Update'}
              </Button>
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      ))}
    </>
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ padding: '2em' }}>
          <Table celled collapsing>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan='6'>API Gateway APIs</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell collapsing sorted='ascending'>API Name</Table.HeaderCell>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Stage</Table.HeaderCell>
                <Table.HeaderCell>API Type</Table.HeaderCell>
                <Table.HeaderCell>Displayed</Table.HeaderCell>
                <Table.HeaderCell>Update</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.sortByUsagePlan()}
            </Table.Body>
          </Table>
        </div>
      </div>
    )
  }
})
