// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import { useLocation } from "react-router-dom";
import { observer } from 'mobx-react'
import { useAuth0 } from "../../react-auth0-spa";
import Sidebar from 'components/Sidebar/Sidebar'
import MenuLink from 'components/MenuLink'

export const SideNav = observer(() => {
  const { isAdmin, isApiManager, isAppManager } = useAuth0();
    const location = useLocation();
    const { pathname } = location;

  return isAdmin() && (
    <Sidebar>
        <div id="sidebar-container" className="sidebar-expanded d-none d-md-block">
        <ul className="list-group sticky-top sticky-offset">
            <li className={pathname.startsWith('/admin/apis') ? "active": "sidebar-list"}>
                {isApiManager() && <MenuLink to='/admin/apis'>APIs</MenuLink>}
            </li>
            <li className={pathname.startsWith('/admin/applications') ? "active": "sidebar-list"}>
                {isAppManager() && <MenuLink to='/admin/applications'>APPLICATIONS</MenuLink>}
            </li>
        </ul>
        </div>
    </Sidebar>
  )
})
