import React from 'react'
// import { store } from 'services/state.js'
//import { observer } from 'mobx-react'

import TestingCredentialField from 'components/API/TestingCredientialField'

// semantic-ui
import { Header, Icon} from 'semantic-ui-react'

// services
import { getTokenUrl, getPublicAPIAudience } from 'services/auth0'

const TestingClientCredentials = (props) => {

    return (
        <div>
            {props.loading ? (
                <>
                <Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}>
                    <Icon name='warning sign' circular />
                    <Header.Content>Loading</Header.Content>
                </Header>
                </>
            ) : (
                <>
                <TestingCredentialField id="domain" label="url" value={getTokenUrl()} />
                <TestingCredentialField label="client_id" value={props.application.client_id} />
                <TestingCredentialField label="client_secret" value={props.application.client_secret} />
                <TestingCredentialField label="audience" value={getPublicAPIAudience()} />
                <TestingCredentialField label="grant_type" value="client_credentials" />
                </>
            )}
        </div >
    );
  }
  export default TestingClientCredentials