import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
import { Header, Segment, Container, Image, Card} from 'semantic-ui-react'

export const HomePage = observer(() => (
  <>
    <Segment vertical textAlign='left' style={{ padding: '40px 0px', margin: '0px !important' }}>
      <Image centered size='medium' src='/custom-content/home-image.png' />
      <Container textAlign='center' fluid text style={{ padding: '0px 0px 40px 0px'}}>
        <Header as='h1' >{fragments.Home.header}</Header>
        <p>{fragments.Home.tagline}</p>
      </Container>
      <Container fluid text>
        <p>{fragments.Home.description}</p>
      </Container>
    </Segment>
    <Segment vertical >
      <Card.Group centered>
        <Card href="/apis-home" color='blue' data-testid="apis-home-test-link">
          <Image src='/custom-content/Access-Ecosystem-small.png' as='div' centered size='small'/>
          <Card.Content>
            <Card.Header>APIs</Card.Header>
            <Card.Description>Exposed APIs for integration</Card.Description>
          </Card.Content>
        </Card>
        {/* {
          !window.config.isMultitenant &&
          <Card href="/streaming-home" color='blue'>
            <Image src='/custom-content/Events-small.png' as='div' centered size='small'/>
            <Card.Content>
              <Card.Header>Datastreaming</Card.Header>
              <Card.Description>Real-time data access</Card.Description>
            </Card.Content>
          </Card>
        } */}
      </Card.Group>
    </Segment>
  </>
))

export default HomePage
