import React, { useEffect, useState } from 'react'

// semantic-ui
import { Container, Grid, Header, Image, Divider, Dropdown } from 'semantic-ui-react'

import TestingToken from 'components/API/TestingToken'
import GettingStartedResponse from 'components/API/GettingStartedResponse'
import TestingClientCredentials from 'components/API/TestingClientCredentials'
import AccessTokenSampleRequest from 'components/API/AccessTokenSampleRequest'

// services
import { getApplications } from 'services/applications'

const GenerateToken = () => {
  const [application, setApplication] = useState(null)
  const [apps, setApps] = useState([])

  // Initial load
  useEffect(() => {
    async function doAsync() {
      let apps = await getApplications()
      sortApps(apps)
      setApps(apps)
    }

    doAsync()
  }, [])

  const sortApps = (apps) => {
    return apps.sort((a, b) => {
      // Sorts apps in dropdown by name suffix
      let aNum = parseInt(a.name.substring(a.name.lastIndexOf('-') + 1, a.name.length))
      let bNum = parseInt(b.name.substring(b.name.lastIndexOf('-') + 1, b.name.length))
      return aNum > bNum ? 1 : -1
    })
  }

  // const Loading = () => {
  //   return (
  //     <Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}>
  //       <Icon name='warning sign' circular />
  //       <Header.Content>Loading</Header.Content>
  //     </Header>
  //   )
  // }

  const GenerateTokenContent = (props) => {
    return (
      <Container>
        <Image centered size='medium' src='/custom-content/Build-Test-Deploy.png' alt='Build-Test-Deploy icon' />
        <Header as='h2'>GENERATE AN ACCESS TOKEN</Header>
        <p>
          Solifi Open Finance Platform integrations utilize the <a href="https://www.oauth.com/oauth2-servers/access-tokens/client-credentials/" target="_blank" rel="noopener noreferrer">client credentials grant type</a> for providing access to APIs. This type of grant is used for server-to-server interactions, and allows services, daemons, or applications to obtain an access token without any user interaction and without directly impersonating a user. Every request to an API requires a valid access token included in the request's "Authorization" header in the format "Bearer &lt;access_token&gt;".
        </p>
        <p>
          You can use a sample token for testing within the Developer Portal, but integrations using your services will require the implementation of the client credential authentication flow using the credentials below.
        </p>
        <p>
          Admin users can manage Applications in the Applications section of the Admin Panel. Select an Application below to get started.
        </p>

        <Divider hidden />

        <Dropdown
          text={props.application ? props.application.name : 'Select Application...'}
          disabled={!props.apps.length}
          loading={!props.apps.length}
          button
          basic
        >
          <Dropdown.Menu>
            <>
              {
                props.apps.map(app => (
                  <Dropdown.Item
                    key={app.client_id}
                    text={app.name}
                    onClick={() => { setApplication(app) }}
                  />
                ))
              }
            </>
          </Dropdown.Menu>
        </Dropdown>

        <Divider hidden />

        {
          !props.application
            ?
            null
            :
            <Grid container>
              <Grid.Row>
                <Grid.Column>
                  <Header as='h3'>Testing in a sandbox environment</Header>
                  <p>You can copy the following token and then paste it into the Authorization request header field when testing APIs using the Sandbox Testing Tool within the Developer Portal.</p>
                  <TestingToken application={props.application} />
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row style={{ paddingBottom: '40px' }}>
                <Grid.Column>
                  <Header as='h3'>Generating a token for client integrations</Header>
                  <p>To generate a token by using the client credentials grant, send a POST request to the /token endpoint provided below with the client_id, client_secret, audience, and grant_type in the POST request body.</p>
                  {/* TODO Refactor to use more react specific elements - semantic react ui has a "Copy" Input object that's pretty sweet, but the plumbing for it needs to be created*/}

                  <TestingClientCredentials application={props.application} />

                  <Divider hidden />
                  <p>The code below provides an example <a href="https://curl.haxx.se/" target="_blank" rel="noopener noreferrer">curl</a> command for sending a request to the /token endpoint:</p>
                  <AccessTokenSampleRequest application={props.application} />
                  <Divider hidden />
                  {/* TODO Fix the spacing here */}
                  <p>You can then use the information returned in the example response below to make authorized requests to any API:</p>
                  <GettingStartedResponse application={props.application} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
        }
      </Container>
    )
  }

  // return (
  //     <div>{loading ? (<Loading />) : (<GettingStartedContent loading={loading} application={application} />)}</div >
  // );
  return (
    <GenerateTokenContent apps={apps} application={application} />
  );
}
export default GenerateToken
