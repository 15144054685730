import React from 'react'

// services
import { getTokenUrl, getPublicAPIAudience } from 'services/auth0'
//import { getApplications } from 'services/applications'

var tokenSampleStyle = {
    background: 'black',
    border: '1px solid gray',
    padding: '7px 8px',
    color: 'lightgray',
    borderRadius: '5px',
    resize: 'none',
    width: '100%',
    height: '70px'
  };

const AccessTokenSampleRequest = (props) => {
    // const [application, setApplication] = useState({})

    // const refreshApplication = () => getApplications().then(response => setApplication(response[0]))

    // // Initial load
    // useEffect(() => {
    //     refreshApplication().finally(() => setLoading(false))
    // }, [])

    return (
        <div>
            <textarea readOnly id="noter-text-area" name="textarea" value={`curl --request POST \
                --url ${getTokenUrl()} \
                --header 'content-type: application/json' \
                --data '{"client_id":"${props.application.client_id}", "client_secret":"${props.application.client_secret}", "audience":"${getPublicAPIAudience()}", "grant_type":"client_credentials"}'`
            } style={tokenSampleStyle} />
        </div >
    );
  }
  export default AccessTokenSampleRequest