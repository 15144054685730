import React, { Component } from 'react'

export default class StreamingIframe extends Component {
    render() {
        return (
            <iframe
                style={{ width: '100%', height: '100%', overflow: 'visible' }}
                onLoad={loadFrame(this.props.streamingType, this.props.token)}
                frameBorder='0'
                title='streamingIframe'
                data-testid='streamingIframe'
                id={this.props.streamingType}
            />
        )
    }
}

function loadFrame(streamingType, token) {
    if (!token) return

    var iframe = document.getElementById(streamingType)
    if (iframe) {
        setCookie('access_token', token, 7)

        iframe.src = '/custom-content/datastreaming/' + streamingType + '/' + (streamingType === 'dataedo-artifacts' ? 'index.html' : 'Default.htm')
    }
}

function setCookie(name, value, days) {
    var expires = ''
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
