import React from 'react'

import { Button, Table, Icon, Form, Modal, Header } from 'semantic-ui-react'

import { getApplications, deleteApplicationById, updateApplication, createApplication } from 'services/applications'

import { observer } from 'mobx-react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getPublicAPIAudience } from 'services/auth0'

export const AppManagement = observer(class AppManagement extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeModal: '',
      apps: [],
      isLoading: true,
      // Only allow save of Update form if field changed
      oldAppName: '',
      newAppName: '',
      creatingApp: false
    }

    this.clientSecretModalField = React.createRef()

    this.handleOpen = activeModal => this.setState({ activeModal: activeModal })
    this.handleClose = () => this.setState({ activeModal: '' })
  }

  componentDidMount() {
    this.setAppsInState()
  }

  async setAppsInState() {
    await getApplications().then(async apps => {
      this.setState({ apps: apps })
      this.setState({ isLoading: false })
      this.setState({ creatingApp: false });
    }).catch(() => this.showToastError('Error retrieving Applications'))
  }

  showToastError(msg) {
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    })
  }

  toastWrapper(fn, pendingMsg, successMsg, ...params) {
    this.setState({ creatingApp: true });
    toast.promise(
      fn(params).finally(() => {
        this.setAppsInState()
      }),
      {
        pending: {
          render() {
            return pendingMsg
          },
          icon: false
        },
        success: {
          render() {
            return successMsg
          },
          icon: "🟢",
          autoClose: 2000
        },
        error: {
          render({ data }) {
            return data.message
            // return data.message + '\n' + data.stack
          },
          autoClose: false
        }
      }
    )
  }

  async createApp() {
    this.toastWrapper(createApplication, 'Creating Application...', 'Application created successfully')
  }

  async updateApp(app) {
    this.toastWrapper(updateApplication, 'Updating Application...', 'Application updated successfully', app)
  }

  async deleteApp(clientId) {
    this.toastWrapper(deleteApplicationById, 'Deleting Application...', 'Application deleted successfully', clientId)
  }

  editAppSubmit = (event) => {
    event.preventDefault()

    var newAppData = { client_metadata: {} }
    for (let el of event.target.getElementsByTagName('input'))
      if (el.name === 'app_name')
        newAppData.client_metadata[el.name] = el.value
      else
        newAppData[el.name] = el.value

    this.updateApp(newAppData)
  }

  editAppComponent(app) {
    return (
      <Modal
        size='small'
        open={this.state.activeModal === app.client_id}
        onClose={() => {
          this.setState({ oldAppName: '', newAppName: '' })
          this.handleClose()
        }}
        trigger={
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              const appName = app.client_metadata?.app_name

              this.setState({ oldAppName: appName }, () => {
                console.log('oldAppName --> ', this.state.oldAppName)
              })

              this.handleOpen(app.client_id)
            }}
            disabled={this.state.creatingApp}
          >Edit</Button>
        }
      >
        <Modal.Content>
          <Form
            id={'editAppForm-' + app.client_id}
            onSubmit={(event) => {
              this.editAppSubmit(event)
              this.handleClose()
            }}
          >
            <React.Fragment key={app.client_id}>
              <Form.Input
                disabled={true}
                name='name'
                label='Client Name'
                value={app.name}
              />
              <Form.Input
                disabled={true}
                name='client_id'
                label='Client Id'
                value={app.client_id}
              />
              <div className='field'>
                <label>Client Secret</label>
                <div className='ui input'>
                  <input
                    disabled={true}
                    name='client_secret'
                    value={app.client_secret}
                    type='password'
                    ref={this.clientSecretModalField}
                  />
                  <Button
                    className='showHideButton'
                    onClick={(event) => {
                      const inputEl = this.clientSecretModalField.current
                      inputEl.type = inputEl.type === 'password' ? 'text' : 'password'
                      event.target.innerHTML = inputEl.type === 'password' ? 'Show' : 'Hide'
                    }}
                    type='button'
                  >
                    Show
                  </Button>
                </div>
              </div>
              <Form.Input
                name='app_name'
                label='Application Name'
                defaultValue={app.client_metadata?.app_name}
                onChange={(event) => {
                  this.setState({ newAppName: event.target.value })
                }}
              />
              <Form.Input
                disabled={true}
                name='audience'
                label='Audience'
                value={getPublicAPIAudience()}
              />
            </React.Fragment>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            form={'editAppForm-' + app.client_id}
            type='submit'
            disabled={this.state.oldAppName === this.state.newAppName}
          >
            Save
          </Button>
          <Button
            type='button'
            onClick={() => {
              this.setState({ oldAppName: '', newAppName: '' })
              this.handleClose()
            }}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  render() {
    return [
      <ToastContainer
        key='toastContainer'
        position='top-center'
        hideProgressBar={true}
      />,
      this.state.isLoading
        ?
        <Header key='loadingHeader' as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}>
          <Icon name='warning sign' circular />
          <Header.Content>Loading</Header.Content>
        </Header>
        :
        <div key='tableWrapperDiv' style={{ display: 'flex', width: '100%' }}>
          <div style={{ padding: '2em' }}>
            <Table celled collapsing size='small'>
              <Table.Header fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='5'>Applications</Table.HeaderCell>
                  <Table.HeaderCell colSpan='1' style={{ 'borderLeft': 'none', 'text-align': 'center' }}>{`${this.state.apps.length} / 50`}</Table.HeaderCell>
                  <Table.HeaderCell colSpan='1' style={{ 'borderLeft': 'none' }}>
                    <Button onClick={() => this.createApp()} disabled={this.state.creatingApp}>Create</Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Header fullWidth>
                <Table.Row>
                  <Table.HeaderCell collapsing sorted='ascending'>Client Name</Table.HeaderCell>
                  <Table.HeaderCell>Client Id</Table.HeaderCell>
                  <Table.HeaderCell>Client Secret</Table.HeaderCell>
                  <Table.HeaderCell>Application Name</Table.HeaderCell>
                  <Table.HeaderCell>Audience</Table.HeaderCell>
                  <Table.HeaderCell>Edit</Table.HeaderCell>
                  <Table.HeaderCell>Delete</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <>
                  {
                    this.state.apps.map(app => (
                      <React.Fragment key={app.client_id}>
                        <Table.Row>
                          <Table.Cell>
                            {app.name}
                          </Table.Cell>
                          <Table.Cell>
                            {app.client_id}
                          </Table.Cell>
                          <Table.Cell>
                            <div className='ui input'>
                              <input
                                disabled={true}
                                name='client_secret'
                                value={app.client_secret}
                                type='password'
                                style={{ border: 'none' }}
                              />
                              <Button
                                className='showHideButton'
                                onClick={(event) => {
                                  const inputEl = event.target.previousSibling
                                  inputEl.type = inputEl.type === 'password' ? 'text' : 'password'
                                  event.target.innerHTML = inputEl.type === 'password' ? 'Show' : 'Hide'
                                }}
                                type='button'
                              >Show</Button>
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            {app.client_metadata?.app_name}
                          </Table.Cell>
                          <Table.Cell>
                            {getPublicAPIAudience()}
                          </Table.Cell>
                          <Table.Cell>
                            {this.editAppComponent(app)}
                          </Table.Cell>
                          <Table.Cell>
                            <Modal
                              size='small'
                              open={this.state.activeModal === 'confirmDelete-' + app.client_id}
                              onClose={this.handleClose}
                              trigger={
                                <Button
                                  style={{ width: '100%', 'background-color': 'var(--danger)', color: 'white' }}
                                  onClick={() => { this.handleOpen('confirmDelete-' + app.client_id) }}
                                  disabled={this.state.creatingApp}
                                >Delete</Button>
                              }>
                              <Modal.Header content={`Delete Application: ${app.name}`}>
                              </Modal.Header>
                              <Modal.Content>
                                Are you sure?
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  style={{ 'background-color': 'var(--danger)', color: 'white' }}
                                  onClick={() => {
                                    this.deleteApp(app.client_id)
                                    this.handleClose()
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button type='button' onClick={this.handleClose}>Cancel</Button>
                              </Modal.Actions>
                            </Modal>
                          </Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))
                  }
                </>
              </Table.Body>
            </Table>
          </div>
        </div>
    ]
  }
})
