// import _ from 'lodash'
import { apiGatewayClient as getApiGatewayClient } from './api'
//import { store } from './state'

// const getApplicationsWithFilter = async filter => {
//   // await setApiGatewayClient(getApiGatewayClient)
//   apiGatewayClient = await getApiGatewayClient()
//   const response = await apiGatewayClient.get(
//     '/applications',
//     undefined,
//     undefined,
//     { queryParams: { filter } }
//   )
//   //console.log(response.data)
//   return response.data
// }

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')

const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

//export const getApplications = () => getApplicationsWithFilter() ;
export const getApplications = () => fetch('get', '/applications')
export const createApplication = () => fetch('post', '/applications')
export const updateApplication = application => fetch('put', '/applications', { application: application })
export const deleteApplicationById = applicationClientId => fetch('delete', url`/applications/${applicationClientId}`)


//visible for testing
