import React from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from "../react-auth0-spa"

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { validateSession } = useAuth0();
    validateSession()
    return (<Route {...rest} render={(props) => <Component {...rest} {...props} />} />)
}

export default PrivateRoute
