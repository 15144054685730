// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Menu, Image } from 'semantic-ui-react'

import { useAuth0 } from "../react-auth0-spa";

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// components
import MenuLink from 'components/MenuLink'

export const NavBar = observer(
  () => {
    const { logout, isAdmin } = useAuth0();
      return <Menu inverted borderless attached style={{ flex: '0 0 auto' }} stackable>
        <MenuLink id='logo' to='/'>
          <Image size='small' src='/custom-content/solifi-logo-wht-tm.svg' style={{ paddingRight: '10px' }} />
        </MenuLink>

        <MenuLink to='/apis-home'>{fragments.APIs.title}</MenuLink>
        {/* { !window.config.isMultitenant && <MenuLink to='/streaming-home'>{fragments.Streaming.title}</MenuLink> } */}

        <Menu.Menu position='right'>
            {isAdmin() && <MenuLink to='/admin'>ADMIN PANEL</MenuLink>}
            {/* <MenuLink to='/dashboard'>My Dashboard</MenuLink> */}
            <MenuLink onClick={logout}>LOGOUT</MenuLink>
        </Menu.Menu>
      </Menu>
  }
)

export default NavBar
