import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

// semantic-ui
import { Container, Grid, Header, Image, Divider, List, Dropdown } from 'semantic-ui-react'

import TestingToken from 'components/API/TestingToken'

// services
import { getApplications } from 'services/applications'

const GettingStarted = () => {
  const [application, setApplication] = useState(null)
  const [apps, setApps] = useState([])
  // const [loading, setLoading] = useState(true)

  // Initial load
  useEffect(() => {
    async function doAsync() {
      let apps = await getApplications()
      apps = sortApps(apps)
      setApps(apps)
    }

    doAsync()
  }, [])

  const sortApps = (apps) => {
    return apps.sort((a, b) => {
      // Sorts apps in dropdown by name suffix
      let aNum = parseInt(a.name.substring(a.name.lastIndexOf('-') + 1, a.name.length))
      let bNum = parseInt(b.name.substring(b.name.lastIndexOf('-') + 1, b.name.length))
      return aNum > bNum ? 1 : -1
    })
  }

  const GettingStartedContent = (props) => {
    return (
      <Container>
        <Image centered size='medium' src='/custom-content/Sandbox.png' alt='Sandbox icon' />
        <Header as='h2'>GETTING STARTED</Header>
        <Grid container>
          <Grid.Row>
            <Grid.Column>
              <p>Solifi exposes a variety of APIs for different Solifi Open Finance Platform services for developers to consume in their applications. You can find the list of APIs on the <Link to='/apis'>APIs page</Link>, including operations such as:</p>
              <List as='ul'>
                <List.Item as='li'>Creating applications in Rapport</List.Item>
                <List.Item as='li'>Generating pricing information</List.Item>
                <List.Item as='li'>Retrieving customer details</List.Item>
              </List>
              <p>You must use a secure token to authenticate your user id when using the APIs. You can use the <a href="#testing-in-a-sandbox">provided token</a> below to test in the Sandbox Testing tool, but to integrate your application with Solifi Open Finance Platform, you will need to generate a token programmatically. Information on how to do this can be found on the <a href="/generate-an-access-token">Generate an Access Token</a> page.</p>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column>
              <Header as='h3'>Viewing an API</Header>
              <p>Click an operation for an API (for example, "GET" or "POST") to view the following:</p>
              <List as='ul'>
                <List.Item as='li'>Parameters: The elements required in the request body. The Sandbox Testing Tool lists either the specific values required or provides a request body with an example of a request object. You can view the example in either XML or JSON format.</List.Item>
                <List.Item as='li'>Responses: The possible responses for the request.</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column>
              <Header as='h3'>Applying the secure token</Header>
              <List ordered>
                <List.Item>Copy the sample testing token.</List.Item>
                <List.Item>Navigate to an API to test.</List.Item>
                <List.Item>Click <b>Authorize</b> and enter a Value of "Bearer" followed by a space and then paste the token.</List.Item>
                <List.Item>Click <b>Authorize</b>.</List.Item>
              </List>
              <p>Result: The Sandbox Testing Tool makes the API available for testing and displays a lock icon beside each method.</p>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column>
              <Header as='h3' id='testing-in-a-sandbox'>Testing an API</Header>
              <List ordered>
                <List.Item>Navigate to an API method to test.</List.Item>
                <List.Item>Click <b>Try it Out</b> for the API method you want to test.</List.Item>
                <List.Item>Enter parameters as follows:
                  <List.List>
                    <List.Item>If specific required values display, enter the values.</List.Item>
                    <List.Item>If the request body displays, enter a value in the associated placeholder for each element you want to include in the request</List.Item>
                  </List.List>
                  <List.Item><i>Tip</i>: The example may include the type of element in quotation marks after each element (for example, the "CUST.NAME": "string" element indicates that you need to enter a string value).</List.Item>
                </List.Item>
                <List.Item>Click <b>Execute</b>.</List.Item>
              </List>
              <p>Result: When you click <b>Execute</b>, the Sandbox Testing tool does the following:</p>
              <List as='ul'>
                <List.Item as='li'>Displays a sample curl request sent to Solifi Open Finance Platform (including the parameters you entered).</List.Item>
                <List.Item as='li'>Sends the request to Solifi Open Finance Platform.</List.Item>
                <List.Item as='li'>Displays the response and any additional information returned.</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row >
            <Grid.Column>
              <Header as='h3'>Testing in a sandbox environment</Header>
              <p>After selecting an Application you can copy the token and paste it into the Authorization request header field when testing APIs using the Sandbox Testing Tool.</p>

              <Dropdown
                text={props.application ? props.application.name : 'Select Application...'}
                disabled={!props.apps.length}
                loading={!props.apps.length}
                button
                basic
              >
                <Dropdown.Menu>
                  <>
                    {
                      props.apps.map(app => (
                        <Dropdown.Item
                          key={app.client_id}
                          text={app.name}
                          onClick={() => { setApplication(app) }}
                        />
                      ))
                    }
                  </>
                </Dropdown.Menu>
              </Dropdown>

              <Divider hidden />

              <TestingToken application={props.application} />

              <br />
              <p>Before you can integrate with our APIs, you will need to generate a valid access token in your application by authenticating with Solifi Open Finance Platform. The <a href="/generate-an-access-token">Generate an Access Token</a> page provides information on how to set up this authentication mechanism.</p>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row style={{ paddingBottom: '40px' }}>
            <Grid.Column>
              <Header as='h3'>Generate an Access Token</Header>
              <p>You will need to generate an access token programmatically for use in your application in order to integrate with Solifi Open Finance Platform. Information on how to generate this token can be found on the <a href="/generate-an-access-token">Generate an Access Token</a> page.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
  // return (
  //     <div>{loading ? (<Loading />) : (<GettingStartedContent loading={loading} application={application} />)}</div >
  // );
  return (
    <GettingStartedContent apps={apps} application={application} />
  );
}
export default GettingStarted
