import React from 'react'

var spanStyle = {
    display: 'flex'
};

var authPStyle = {
    width: '10%',
    padding: '7px 8px'
};

var authIStyle = {
    background: 'black',
    border: '1px solid gray',
    padding: '7px 8px',
    color: 'lightgray',
    borderRadius: '5px',
    resize: 'none',
    width: '40%'
  };

class TestingCredentialField extends React.Component {
    render() {
        return (
            <span style={spanStyle}>
                <label style={authPStyle}>{this.props.label + ":"}</label>
                <input readOnly type="text" id={this.props.label ? this.props.label : this.props.id} value={this.props.value} style={authIStyle} />
            </span>
        );
    }
}

export default TestingCredentialField
