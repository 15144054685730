import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
import { Header, Segment, Container, Image, Card } from 'semantic-ui-react'

export const StreamingHomePage = observer(() => {

  return (
    <>
      <Segment vertical textAlign='left' style={{ padding: '40px 0px', margin: '0px !important' }}>
        <Container fluid text>
          <Header as='h1' >{fragments.Streaming.header}</Header>
          <p>{fragments.Streaming.description}</p>
        </Container>
      </Segment>
      {/* <Segment vertical style={{ padding: '40px 0px', margin: '0 !important' }}> */}
      <Segment vertical >
        <Card.Group centered>
          <Card href='/streaming-getting-started' color='blue' data-testid="streamingGettingStartedLink">
            <Image src='/custom-content/Out-of-Box-small.png' as='div' centered size='small' />
            <Card.Content>
              <Card.Header>Getting Started with Streaming</Card.Header>
              <Card.Description>How to integrate with Kafka Streaming</Card.Description>
            </Card.Content>
          </Card>
          <Card href="/datamart" color='blue'>
            <Image src='/custom-content/Business-Logic-small.png' as='div' centered size='small' />
            <Card.Content>
              <Card.Header>Datamart</Card.Header>
              <Card.Description>Interactive datamart for researching</Card.Description>
            </Card.Content>
          </Card>
          <Card href="/streaming-faq" color='blue'>
            <Image src='/custom-content/Analytics-Decisioning-small.png' as='div' centered size='small' />
            <Card.Content>
              <Card.Header>FAQ</Card.Header>
              <Card.Description>Frequently asked questions</Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Segment>
    </>
  )
})

export default StreamingHomePage
