import React from "react";
import { Button} from 'semantic-ui-react'
import { store } from 'services/state.js'

// services
import { updateAuth0AccessTokenFromApp } from 'services/auth0'

const testingTokenStyle = {
    background: 'black',
    border: '1px solid gray',
    padding: '7px 8px',
    color: 'lightgray',
    borderRadius: '5px',
    resize: 'none',
    width: '100%',
    height: '150px'
}

export default class TestingToken extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            copySuccess: '',
            accessToken: '',
            applicationName: ''
        }
    }
    
    componentDidMount() { 
        this._isMounted = true; // workaround to correct warnings about promises that may finish after the component has unmounted. Prevents memory leaks
        if (!this.props.application || !this.props.application.name || this.state.applicationName === this.props.application.name) return
        updateAuth0AccessTokenFromApp(this.props.application).then(() => {
            if (this._isMounted) {
                this.setState({accessToken: store.auth0.accessToken})
                this.setState({applicationName: this.props.application.name})
            }
        }) 
    };

    componentWillUnmount() {
        this._isMounted = false;
      }

    copyToClipboard = (e) => {
        this.tokenTextArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({copySuccess: 'Copied!'});
    };

    render() {
        return (
            <div>
                <textarea readOnly id="noter-text-area" name="tokenTextArea" ref={(tokenTextArea) => this.tokenTextArea = tokenTextArea} value={"Bearer " + this.state.accessToken} style={testingTokenStyle} />
                {
                    /* Logical shortcut for only displaying the button if the copy command exists */
                    document.queryCommandSupported('copy') &&
                    <div style={{ padding: '10px 0 0 0' }}>
                        <Button onClick={this.copyToClipboard}>Copy</Button>
                        {this.state.copySuccess}
                        {/* TODO modify the "copied" message or move it into the text area */}
                    </div>
                }
            </div>
        );
    }
}

