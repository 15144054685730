import React from 'react'

// semantic-ui
import { Sidebar, Segment, Menu } from 'semantic-ui-react'

export default class PageWithSidebar extends React.Component {
  constructor() {
    super()

    this.state = {
      scroll: 0
    }
  }

  componentDidMount() {
    // After render reset the scroll position of Sidebar.Pusher. Prevents bug where scroll jumps to top of page on render of which the root cause remains undetermined after much debugging/research.
    let el = document.getElementById('pusher')
    el && (el.scrollTop = this.scroll)
  }

  render() {
    // Before rendering save the scroll position of Sidebar.Pusher. See componentDidMount.
    let el = document.getElementById('pusher')
    el && (this.scroll = el.scrollTop)

    const { sidebarContent, children, SidebarPusherProps } = this.props
    return (
      <Sidebar.Pushable
        as={Segment}
        style={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          border: 'none',
          margin: 0,
          borderRadius: 0,
          boxShadow: 'none'
        }}
      >
        <Sidebar
          as={Menu}
          inverted
          attached
          borderless
          vertical
          visible
          style={{
            margin: 0,
            borderRadius: 0,
            flex: '0 0 auto',
            position: 'relative',
            overflowY: 'scroll',
            width: '260px'
          }}
        >
          {sidebarContent}
        </Sidebar>
        <Sidebar.Pusher
          id='pusher'
          style={{
            marginLeft: 0,
            position: 'absolute',
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto',
            width: 'calc(100% - 260px)'
          }}
          {...SidebarPusherProps}
        >
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    )
  }
}
