import React from 'react'

// mobx
import { observer } from 'mobx-react'

// semantic-ui
import { Container } from 'semantic-ui-react'

import StreamingIframe from 'components/StreamingIframe'

export const StreamingIframeWrapper = observer((props) => {
  return (
    <Container fluid style={{ height: '100%' }}>
      <StreamingIframe {...props} />
    </Container>
  )
})

export default StreamingIframeWrapper
